import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { QuizData } from '../interfaces/quizData';
import { DataService } from '../services/data.service';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-quiz-list',
  templateUrl: './quiz-list.component.html',
  styleUrls: ['./quiz-list.component.css'],
})
export class QuizListComponent implements OnInit, OnDestroy {
  quizList = ['Live Swaminarayan Quiz'];
  quizStartedSub: Subscription;
  demoQuizSub: Subscription;

  constructor(
    private socketService: SocketIOService,
    private router: Router,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.quizStartedSub = this.socketService
      .QuizStatusForAdmin()
      .subscribe((msg: QuizData) => {
        this.dataService.quizData = msg;
        this.router.navigate(['/wait-screen']);
      });

    this.demoQuizSub = this.socketService
      .demoQuizStatus()
      .subscribe((msg: QuizData) => {
        this.dataService.quizData = msg;
        this.router.navigate(['/demo-quiz']);
      });
  }

  onQuizClicked(quizName: string): void {
    console.log(quizName);
    this.socketService.startSelectedQuiz(quizName);
  }

  onDemoClicked(quizName: string): void {
    console.log(`Demo started for ${quizName}`);
    this.socketService.startDemoQuiz(quizName);
  }

  ngOnDestroy(): void {
    this.quizStartedSub.unsubscribe();
    this.demoQuizSub.unsubscribe();
  }
}
