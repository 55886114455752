import { Injectable } from '@angular/core';
import { LeaderBoard } from '../interfaces/leaderBoard';
import { QuizData } from '../interfaces/quizData';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public quizData: QuizData;
  public currentQuesiton: number;
  public LeaderBoard: LeaderBoard[] = [];
}
