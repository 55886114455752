export interface LeaderBoard {
  playerName: string;
  playerScore: number;
  isPositionChanged?: PositionChanged;
  rank?: number;
}

export enum PositionChanged {
  Same = 'NotChanged',
  Up = 'Up',
  Down = 'Down',
}
