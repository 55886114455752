import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Track } from 'ngx-audio-player';
import { AudioQues, QuizData, quizData, VideoQues } from '../interfaces/quizData';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-demo-quiz',
  templateUrl: './demo-quiz.component.html',
  styleUrls: ['./demo-quiz.component.css'],
})
export class DemoQuizComponent implements OnInit {
  quizData: QuizData;
  currentQuizObj: quizData;
  currentQuestion: number = 0;
  totalQuestions: number;

  opt1Disabled: boolean = false;
  opt2Disabled: boolean = false;
  opt3Disabled: boolean = false;
  opt4Disabled: boolean = false;

  showAudioVideo = false;
  safeURL: SafeResourceUrl = '';
  msaapDisplayTitle = false;
  msaapDisplayVolumeControls = true;
  msaapDisplayArtist = false;
  msaapDisplayDuration = true;
  msaapDisplayRepeatControls = true;
  msaapPlaylist: Track[] = [];

  correctAns: number;

  constructor(
    private dataService: DataService,
    private router: Router,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.quizData = this.dataService.quizData;
    if (this.dataService.currentQuesiton == undefined) {
      this.dataService.currentQuesiton = 0;
    }
    this.currentQuestion = this.dataService.currentQuesiton;
    this.totalQuestions = this.quizData?.quizData?.length;
    this.currentQuizObj = this.quizData?.quizData[this.currentQuestion];
    this.correctAns =
      this.currentQuizObj?.question_type == 1 ? 1 : this.correctAnsCount();
    this.disableWrongOptions();

    this.addAudioVideoData();
  }

  showNextQuestion(): void {
    if (this.currentQuestion > this.totalQuestions - 2) {
      this.router.navigate(['/quiz-list']);
    }

    this.currentQuestion++;
    this.setUpCurrentQuestion();
  }

  showPrevQuestion(): void {
    if (this.currentQuestion != 0) {
      this.currentQuestion--;
      this.setUpCurrentQuestion();
    }
  }

  disableWrongOptions(): void {
    if (this.currentQuizObj?.question_type == 2) {
      this.disableMultiSelectOptions();
    } else {
      if (this.currentQuizObj?.option_1 != this.currentQuizObj?.correct_answer)
        this.opt1Disabled = true;
      if (
        this.currentQuizObj?.option_2 != this.currentQuizObj?.correct_answer
      ) {
        this.opt2Disabled = true;
      }
      if (
        this.currentQuizObj?.option_3 != this.currentQuizObj?.correct_answer
      ) {
        this.opt3Disabled = true;
      }
      if (
        this.currentQuizObj?.option_4 != this.currentQuizObj?.correct_answer
      ) {
        this.opt4Disabled = true;
      }
    }
  }

  disableMultiSelectOptions(): void {
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_1
      ) === -1
    ) {
      this.opt1Disabled = true;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_2
      ) === -1
    ) {
      this.opt2Disabled = true;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_3
      ) === -1
    ) {
      this.opt3Disabled = true;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_4
      ) === -1
    ) {
      this.opt4Disabled = true;
    }
  }

  addAudioVideoData() {
    if (this.currentQuizObj?.show_video != VideoQues.noVieo) {
      this.showAudioVideo = true;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        this.currentQuizObj?.video_URL
      );
    } else if (this.currentQuizObj?.show_audio != AudioQues.noAudio) {
      this.showAudioVideo = true;
      this.msaapPlaylist = [];
      this.msaapPlaylist.push({
        title: 'Live Quiz',
        link: this.currentQuizObj?.audio_URL,
      });
    } else {
      this.showAudioVideo = false;
    }
  }

  setUpCurrentQuestion(): void {
    this.opt1Disabled = false;
    this.opt2Disabled = false;
    this.opt3Disabled = false;
    this.opt4Disabled = false;

    this.currentQuizObj = this.quizData?.quizData[this.currentQuestion];
    this.correctAns =
      this.currentQuizObj?.question_type == 1 ? 1 : this.correctAnsCount();
    this.disableWrongOptions();
    this.addAudioVideoData();
    this.currentQuizObj?.correct_answer?.split(',');
  }

  correctAnsCount(): number {
    let count = 0;
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_1
      ) > -1
    ) {
      count++;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_2
      ) > -1
    ) {
      count++;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_3
      ) > -1
    ) {
      count++;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_4
      ) > -1
    ) {
      count++;
    }

    return count;
  }
}
