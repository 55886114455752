import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  isAuthenticated: boolean;

  constructor() {}

  isAdminAuthenticated(): boolean {
    return this.isAuthenticated;
  }
}
