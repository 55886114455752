<div fxLayout="column" class="mainDiv">

    <div class="page-leaderboard">
        <div class="topleftghanu">
            <img src="../../assets/Images/left_Maharaj_Blessing.png">
        <div class="Container">
            <div [ngClass]="(podactive)?'podactive':'podinactive'" class="LightContainer">
            <div class="Light"></div>
        </div>
    </div>
        </div>
        <div id="contain-all" class=" slideout-panel">
            <section class="ranking">
                <div class="">
                    <div class="ranking-table">

                        <div [ngClass]="(podactive)?'podactive':'podinactive'" class="podiumcntr"  [ngStyle]="podiumcntrStyle ? {'width': '50%'} : {'width': '100%'}">
                            <div class="podium">
                                <!-- 1st Place-->
                                <div class="box-1">
                                    <div class="text-center" style="height: 92px;">
                                        <span [ngClass]="(showPos1)?'visibilityvisible':'visibilityhidden'"
                                            class="pos-1 visibilityhidden"><span>{{newLeaderBoardList[2]?.playerName}}</span>{{newLeaderBoardList[2]?.playerScore}}</span>
                                    </div>
                                    <div class="runner-2" [ngClass]="(showPos1)?'active':''" id="runner-2-main"
                                        [ngStyle]="bodyStyle ? {'transform': 'translateY(-50px)'} : {'transform': 'translateY(0px)'}">
                                        <div class="text-center">
                                            <div class="runner-2-face"> </div>
                                        </div>
                                        <div class="text-center">
                                            <div class="runner-2-shirt">
                                                <div class="medal-3">
                                                    <div class="bronze-medal"> <span class="number">3</span> </div>
                                                    <div class="ribbon-1"> </div>
                                                    <div class="ribbon-2"> </div>
                                                    <div class="ribbon-3"> </div>
                                                    <div class="ribbon-4"> </div>
                                                    <span
                                                        class="runner2-jerseyno">{{newLeaderBoardList[2]?.playerName?.charAt(0)}}</span>
                                                </div>
                                                <div class="runner-2-hand-1" id="runner-2-hand-1"
                                                    [ngStyle]="handStyle ? {'animation-name' : 'runner-2-hand-celebrate', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'runner-2-normal', 'animation-fill-mode': 'forwards'}">
                                                </div>
                                                <div class="runner-2-hand-2" id="runner-2-hand-2"> </div>

                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <div class="runner-2-pant">
                                                <div class="runner-2-leg-1" id="runner-2-leg-1"
                                                    [ngStyle]="legStyle ? {'animation-name' : 'runner-2-leg-1', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'runner-2-leg-1-normal', 'animation-fill-mode': 'forwards'}">
                                                </div>
                                                <div class="runner-2-leg-2" id="runner-2-leg-2"
                                                    [ngStyle]="legStyle ? {'animation-name' : 'runner-2-leg-2', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'runner-2-leg-2-normal', 'animation-fill-mode': 'forwards'}">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 2nd Place -->
                                <div class="box-2">
                                    <div class="text-center" style="height: 87px;">
                                        <span [ngClass]="(showPos2)?'visibilityvisible':'visibilityhidden'"
                                            class="pos-2 visibilityhidden"><span>{{newLeaderBoardList[0]?.playerName}}</span>{{newLeaderBoardList[0]?.playerScore}}</span>
                                    </div>

                                    <!-- Winner -->
                                    <div class="winner" [ngClass]="(showPos2)?'active':''" id="winner"
                                        [ngStyle]="bodyStyle ? {'transform': 'translateY(-50px)'} : {'transform': 'translateY(0px)'}">
                                        <!-- Face -->
                                        <div class="text-center">
                                            <div class="face"> </div>
                                        </div>
                                        <!-- Shirt -->
                                        <div class="text-center">
                                            <div class="medal-1">
                                                <div class="gold-medal"> <span class="number">1</span> </div>
                                                <div class="ribbon-1"> </div>
                                                <div class="ribbon-2"> </div>
                                                <div class="ribbon-3"> </div>
                                                <div class="ribbon-4"> </div>
                                                <span
                                                    class="winner-jerseyno">{{newLeaderBoardList[0]?.playerName?.charAt(0)}}</span>
                                            </div>
                                            <div class="shirt">
                                                <div class="hand-1" id="hand-1"
                                                    [ngStyle]="handStyle ? {'animation-name' : 'anim-hand-1', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'anim-out-hand-1'}">
                                                </div>
                                                <div class="hand-2" id="hand-2"
                                                    [ngStyle]="handStyle ? {'animation-name' : 'anim-hand-2', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'anim-out-hand-2'}">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Pant -->
                                        <div class="pant">
                                            <div class="leg-1" id="leg-1"
                                                [ngStyle]="legStyle ? {'animation-name' : 'anim-leg-1', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'anim-out-leg-1'}">
                                            </div>
                                            <div class="leg-2" id="leg-2"
                                                [ngStyle]="legStyle ? {'animation-name' : 'anim-leg-2', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'anim-out-leg-2'}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 3rd Place -->
                                <div class="box-3">
                                    <div class="text-center">
                                        <span [ngClass]="(showPos3)?'visibilityvisible':'visibilityhidden'"
                                            class="pos-3 visibilityhidden"><span>{{newLeaderBoardList[1]?.playerName}}</span>{{newLeaderBoardList[1]?.playerScore}}</span>
                                    </div>
                                    <div class="runner-1" [ngClass]="(showPos3)?'active':''" id="runner-1-main"
                                        [ngStyle]="bodyStyle ? {'transform': 'translateY(-50px)'} : {'transform': 'translateY(0px)'}">
                                        <div class="text-center">
                                            <div class="runner-1-face"> </div>
                                        </div>
                                        <div class="text-center">
                                            <div class="medal-2">
                                                <div class="silver-medal"> <span class="number">2</span> </div>
                                                <div class="ribbon-1"> </div>
                                                <div class="ribbon-2"> </div>
                                                <div class="ribbon-3"> </div>
                                                <div class="ribbon-4"> </div>
                                                <span
                                                    class="runner1-jerseyno">{{newLeaderBoardList[1]?.playerName?.charAt(0)}}</span>
                                            </div>
                                            <div class="runner-1-shirt">
                                                <div class="runner-1-hand-1"> </div>
                                                <div class="runner-1-hand-2" id="runner-1-hand"
                                                    [ngStyle]="handStyle ? {'animation-name' : 'anim-runner-1-celebrate', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'runner-1-normal'}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="runner-1-pant">
                                            <div class="runner-1-leg-1" id="runner-1-leg-1"
                                                [ngStyle]="legStyle ? {'animation-name' : 'anim-runner-1-leg-1', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'anim-out-runner-1-leg-1'}">
                                            </div>
                                            <div class="runner-1-leg-2" id="runner-1-leg-2"
                                                [ngStyle]="legStyle ? {'animation-name' : 'anim-runner-1-leg-2', 'animation-fill-mode': 'forwards'} : {'animation-name' : 'anim-out-runner-1-leg-2'}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [ngClass]="(showLeaderTable)?'leadertablevisible':'leadertablehidden'">
                            <div style="width: 90%;">
                            <div class="ranking-table-header-row">
                                <div class="ranking-table-header-data h6">Rank</div>
                                <div class="ranking-table-header-data h6">Name</div>
                                <div class="ranking-table-header-data h6">Score</div>
                            </div>

                            <div class="ranking-table-body">
                                <div class="ranking-table-row" *ngFor="let item of newLeaderBoardList | slice:3 ">
                                    <div class="ranking-table-data"> {{item.rank}} </div>
                                    <div class="ranking-table-data"> {{item.playerName}} </div>
                                    <div class="ranking-table-data"> {{item.playerScore}} </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            <div class="light"></div>
            <div class="light"></div>
            </section>
        </div>
    </div>
</div>