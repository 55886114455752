import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LeaderBoard, PositionChanged } from '../interfaces/leaderBoard';
import { DataService } from '../services/data.service';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.css'],
  animations: [
    trigger('anim', [
      // transition('* => void', [
      //   style({
      //     height: '*',
      //     overflow: 'visible',
      //     opacity: '1',
      //     transform: 'translateX(0)',
      //   }),
      //   group([
      //     animate('2s ease', style({ height: '0', 'margin-bottom': '0' })),
      //     animate(
      //       '2s ease',
      //       style({ opacity: '0', transform: 'translateX(20px)' })
      //     ),
      //   ]),
      // ]),
      // transition('void => *', [
      //   style({ height: '0', opacity: '0', transform: 'translateX(20px)' }),
      //   group([
      //     animate('2s ease', style({ height: '*' })),
      //     animate('2s ease', style({ opacity: 1, transform: 'translateX(0)' })),
      //   ]),
      // ]),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class LeaderboardComponent implements OnInit {
  leaderBoardList: LeaderBoard[] = [];
  newLeaderBoardList: LeaderBoard[] = [];
  previousLeaderBoardList: LeaderBoard[] = [];

  plyName: string;
  plyScore: number;

  applyAnimation: boolean = false;

  constructor(
    private router: Router,
    private socketService: SocketIOService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.leaderBoardList = [];
    this.newLeaderBoardList = [];
    this.applyAnimation = false;
    this.leaderBoardList = this.dataService.LeaderBoard.map((x) =>
      Object.assign({}, x)
    );
    this.previousLeaderBoardList = [...this.leaderBoardList];
    this.socketService.getLeaderBoard().subscribe((leaderList: any) => {
      for (let i = 0; i < leaderList.length; i++) {
        if (i % 2 == 0) {
          this.plyName = leaderList[i];
        } else {
          this.plyScore = leaderList[i];
          this.newLeaderBoardList.push({
            playerName: this.plyName,
            playerScore: this.plyScore,
          });
          this.plyName = '';
          this.plyScore = 0;
        }
      }
    });

    setTimeout(() => {
      this.RefreshBoard();
    }, 2000);
  }

  showNextQuestion(): void {
    if (
      this.dataService.currentQuesiton >
      this.dataService.quizData?.quizData?.length - 1
    ) {
      this.router.navigate(['/final-leaderboard']);
    } else {
      this.socketService.showNextQuestion();
      this.router.navigate(['/question-only']);
    }
  }

  // push(newPlayer) {
  //   this.leaderBoardList.splice(1, 0, newPlayer);
  //   this.leaderBoardList.sort((a, b) => b.playerScore - a.playerScore);
  // }

  // remove(plyName) {
  //   let index = this.leaderBoardList.findIndex(
  //     (i, o) => i.playerName == plyName
  //   );
  //   this.leaderBoardList.splice(index, 1);
  // }

  RefreshBoard() {
    this.applyAnimation = true;
    this.leaderBoardList = [];
    // const removedIndexList = [];
    // this.leaderBoardList.forEach((item, index) => {
    //   var found = this.newLeaderBoardList.find(
    //     (n) => n.playerName == item.playerName
    //   );
    //   if (!found) {
    //     removedIndexList.push(item.playerName);
    //   } else {
    //     this.leaderBoardList[index] = found;
    //   }
    // });

    // removedIndexList.forEach((i) => {
    //   this.remove(i);
    // });

    // this.leaderBoardList.sort((a, b) => b.playerScore - a.playerScore);

    setTimeout(() => {
      // this.newLeaderBoardList.forEach((item, index) => {
      //   var found = this.leaderBoardList.find(
      //     (n) => n.playerName == item.playerName
      //   );
      //   if (!found) {
      //     this.push(item);
      //   }
      // });

      this.leaderBoardList = [...this.newLeaderBoardList];

      // Add rank
      let rank = 1;
      this.leaderBoardList.forEach((i) => {
        i.rank = rank;
        rank++;
      });

      this.dataService.LeaderBoard = this.leaderBoardList.map((x) =>
        Object.assign({}, x)
      );

      this.updatePositionStatus();
    }, 1000);
  }

  updatePositionStatus() {
    if (this.previousLeaderBoardList.length > 0) {
      this.leaderBoardList.forEach((prev, index) => {
        let prevIndex = this.previousLeaderBoardList.findIndex(
          (newI) => prev.playerName == newI.playerName
        );
        if (prevIndex != undefined && prevIndex !== -1) {
          if (prevIndex == index) {
            this.leaderBoardList[index].isPositionChanged =
              PositionChanged.Same;
          } else if (prevIndex > index) {
            this.leaderBoardList[index].isPositionChanged = PositionChanged.Up;
          } else if (prevIndex < index) {
            this.leaderBoardList[index].isPositionChanged =
              PositionChanged.Down;
          }
        }
        // Player was not in previous LeaderBoard, so it has come up
        if (prevIndex == -1) {
          this.leaderBoardList[index].isPositionChanged = PositionChanged.Up;
        }
      });
    }
  }
}
