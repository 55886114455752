<div fxLayout="column">
    <div fxLayoutAlign="center center" class="quizList">List Of Available Quiz</div>
    <div fxLayout="column" fxLayoutGap="10px">
        <mat-card *ngFor='let quiz of quizList'>
            <span> {{quiz}} </span>
            <button mat-raised-button color="primary" style="margin-left: 20px;" (click)="onQuizClicked(quiz)">
                Start Quiz
            </button>
            <button mat-raised-button color="warn" style="margin-left: 20px;" (click)="onDemoClicked(quiz)">
                Show Demo
            </button>
        </mat-card>
    </div>
</div>