<div fxLayout="column" fxLayoutGap="5px">
    <div style=" background-color: whitesmoke;" fxLayout="column">
        <div style="height: 80px;" fxLayout="row">
            <span fxLayoutAlign="center center" fxFlex="5" class="questionHeader1">{{currentQuestion +
                1}}/{{totalQuestions}}</span>
            <span fxLayoutAlign="center center" fxFlex="80"
                class="questionHeader">{{currentQuizObj?.question_text}}
            </span>
            <span fxLayoutAlign="center center" fxFlex="15" class="questionHeader1">
                Correct Ans: {{correctAns}}
            </span>
        </div>
    </div>
    <div style="height: 25px;"></div>
    <div fxLayout="row" style="height: 50vh;">
        <div fxFlex="10" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <button mat-raised-button color="warn" class="nextQuestionButton"
                    (click)="showPrevQuestion()">Previous</button>
            </div>
        </div>
        <div fxFlex="80" fxLayoutGap="10px" style="text-align: center;">
            <div *ngIf="showAudioVideo" fxLayout="row">
                <div fxFlex="50">
                    <img mat-card-image style="width: auto; height: 400px;" class="questionImage"
                        src={{currentQuizObj?.question_media}} />
                </div>
                <div *ngIf="currentQuizObj?.show_audio" fxFlex="50" fxLayoutAlign="center center">
                    <ngx-audio-player #audio_player [playlist]="msaapPlaylist" [displayTitle]="msaapDisplayTitle"
                        [autoPlay]="false" muted="muted" [displayVolumeControls]="msaapDisplayVolumeControls"
                        [displayArtist]="msaapDisplayArtist" [displayRepeatControls]="msaapDisplayRepeatControls"
                        [displayDuration]="msaapDisplayDuration" [expanded]="true">
                    </ngx-audio-player>
                </div>
                <div *ngIf="currentQuizObj?.show_video" fxFlex="50">
                    <iframe width="1060" height="400px" class="questionImage" id="iFrameID" [src]='safeURL' frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
            <div *ngIf="!showAudioVideo">
                <img mat-card-image style="width: auto; height: 400px;" class="questionImage"
                        src={{currentQuizObj?.question_media}} />
            </div>
        </div>
        <div fxFlex="10" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <button mat-raised-button color="warn" class="nextQuestionButton"
                    (click)="showNextQuestion()">Next</button>
            </div>
        </div>
    </div>
    <div style="height: 230px;position: absolute;bottom: 0;width: 100%;padding: 0 20px;" fxLayout="column"
        fxLayoutGap="10px">
        <div fxLayout="row" style="height: 100px;" fxLayoutGap="10px">
            <button [disabled]="opt1Disabled" *ngIf="currentQuizObj?.option_1?.length > 0" fxFlex="50" mat-raised-button
                color="accent"><span class="optionindicater">A</span> <span>{{currentQuizObj?.option_1}}</span></button>
            <button [disabled]="opt2Disabled" *ngIf="currentQuizObj?.option_2?.length > 0" fxFlex="50" mat-raised-button
                color="warn"><span class="optionindicater">B</span> <span>{{currentQuizObj?.option_2}}</span></button>
        </div>
        <div fxLayout="row" style="height: 100px;" fxLayoutGap="10px">
            <button [disabled]="opt3Disabled" *ngIf="currentQuizObj?.option_3?.length > 0" fxFlex="50" mat-raised-button
                color="primary"><span class="optionindicater">C</span>
                <span>{{currentQuizObj?.option_3}}</span></button>
            <button [disabled]="opt4Disabled" *ngIf="currentQuizObj?.option_4?.length > 0" fxFlex="50"
                [ngStyle]="{'background-color': (opt4Disabled==false) ? 'darkcyan' : '', 'color': (opt4Disabled==false) ? 'white' : ''}"
                mat-raised-button><span class="optionindicater">D</span>
                <span>{{currentQuizObj?.option_4}}</span></button>
        </div>
    </div>
</div>