import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Track } from 'ngx-audio-player';
import { AudioQues, quizData, VideoQues } from '../interfaces/quizData';
import { DataService } from '../services/data.service';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-question-only',
  templateUrl: './question-only.component.html',
  styleUrls: ['./question-only.component.css'],
})
export class QuestionOnlyComponent implements OnInit, OnDestroy {
  currentQuizQuestion: quizData;
  currentNumber: number;
  videoQues = VideoQues;
  audioQues = AudioQues;
  safeURL: SafeResourceUrl = '';
  msaapDisplayTitle = false;
  msaapDisplayVolumeControls = true;
  msaapDisplayArtist = false;
  msaapDisplayDuration = true;
  msaapDisplayRepeatControls = true;
  msaapPlaylist: Track[] = [];

  player: any;

  constructor(
    private dataService: DataService,
    private router: Router,
    private socketService: SocketIOService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.dataService.quizData == undefined) {
      this.socketService.handleAdminRefresh();
    } else {
      this.currentQuizQuestion = this.dataService.quizData?.quizData[
        this.dataService.currentQuesiton == undefined
          ? 0
          : this.dataService.currentQuesiton
      ];
      this.currentNumber =
        this.dataService.currentQuesiton == undefined
          ? 1
          : this.dataService.currentQuesiton + 1;

      if (this.currentQuizQuestion?.show_video != VideoQues.noVieo) {
        this.currentQuizQuestion.video_URL =
          this.currentQuizQuestion.video_URL + '&enablejsapi=1&modestbranding=1';
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.currentQuizQuestion?.video_URL
        );
      }

      if (
        this.currentQuizQuestion?.show_audio == this.audioQues.beforeQuestion ||
        this.currentQuizQuestion?.show_audio ==
          this.audioQues.beforeQuestionAudioOnly
      ) {
        this.msaapPlaylist.push({
          title: 'Live Quiz',
          link: this.currentQuizQuestion?.audio_URL,
        });
      }
    }

    this.init();

    this.socketService.RefreshedDataFromServer().subscribe((resp) => {
      this.dataService.currentQuesiton = resp.currentQuestion;
      this.dataService.quizData = resp.quizData;
      this.currentQuizQuestion = this.dataService.quizData?.quizData[
        this.dataService.currentQuesiton == undefined
          ? 0
          : this.dataService.currentQuesiton
      ];
      this.currentNumber =
        this.dataService.currentQuesiton == undefined
          ? 1
          : this.dataService.currentQuesiton + 1;

      if (this.currentQuizQuestion?.show_video != VideoQues.noVieo) {
        this.currentQuizQuestion.video_URL =
          this.currentQuizQuestion.video_URL + '&enablejsapi=1&modestbranding=1';
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.currentQuizQuestion?.video_URL
        );
      }

      if (
        this.currentQuizQuestion?.show_audio == this.audioQues.beforeQuestion ||
        this.currentQuizQuestion?.show_audio ==
          this.audioQues.beforeQuestionAudioOnly
      ) {
        this.msaapPlaylist.push({
          title: 'Live Quiz',
          link: this.currentQuizQuestion?.audio_URL,
        });
      }
    });
  }

  nextClicked(): void {
    this.socketService.setQuizStartedFromQuestionScreen(
      this.dataService.currentQuesiton //Pass currentQues to users joining in between
    );
    this.router.navigate(['/quiz-screen']);
  }

  init() {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  startVideo() {
    this.player = new window['YT'].Player('iFrameID', {
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
  }

  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.ENDED:
        this.player.loadVideoByUrl({
          mediaContentUrl: this.currentQuizQuestion.video_URL,
          startSeconds: this.currentQuizQuestion?.video_start,
          endSeconds: this.currentQuizQuestion?.video_end,
        });
        this.player.pauseVideo();
        break;
    }
  }

  ngOnDestroy() {
    window['YT'] = null;
  }
}
