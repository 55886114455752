<div fxLayout="column" fxLayoutGap="5px">
    <div style=" background-color: whitesmoke;" fxLayout="column">
        <div style="height: 80px;" fxLayout="row">
            <span fxLayoutAlign="center center" fxFlex="5" class="questionHeader1">{{currentQuestion +
                1}}/{{totalQuestions}}</span>
            <span fxLayoutAlign="center center" fxFlex="95"
                class="questionHeader">{{currentQuizObj?.question_text}}</span>
        </div>
    </div>
    <div style="height: 25px;"></div>
    <div *ngIf="!showChart" fxLayout="row" style="height: 50vh;">
        <div fxFlex="20" fxLayoutAlign="center center">
            <div class="cloclwrap">
                <div class="porel">
                    <progress id="progress" [ngClass]="(currentQuizObj?.time_in_sec<=20)?'clock1':'clock2'"
                        value={{counter}}></progress>
                    <span class="closcktime">{{counter}}</span>
                </div>
            </div>
        </div>
        <div fxFlex="60" fxLayoutGap="10px" style="text-align: center;">
            <img mat-card-image style="width: auto;height: 100%;max-height: 100%;" class="questionImage"
                src={{currentQuizObj?.question_media}} />
        </div>
        <div fxFlex="20" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
                <div style="font-size: 24px;margin-bottom: 10px;font-weight: 400;">Answers</div>
                <div style="font-size: 52px;margin-top: 10px;padding-top: 7px;">{{totalAnswers}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="showChart" style="height: 45vh; height: 100%;">
        <button mat-raised-button color="warn" class="nextQuestionButton" (click)="showLeaderBoard()">Next</button>
        <app-bar-chart [barChartLabels]="barChartLabels" [barChartData]="barChartData"
            *ngIf="barChartLabels && barChartData">
        </app-bar-chart>
    </div>
    <div style="height: 230px;position: absolute;bottom: 0;width: 100%;padding: 0 20px;" fxLayout="column"
        fxLayoutGap="10px">
        <div fxLayout="row" style="height: 100px;" fxLayoutGap="10px">
            <button [disabled]="opt1Disabled" *ngIf="currentQuizObj?.option_1?.length > 0" fxFlex="50" mat-raised-button
                color="accent"><span class="optionindicater">A</span> <span>{{currentQuizObj?.option_1}}</span></button>
            <button [disabled]="opt2Disabled" *ngIf="currentQuizObj?.option_2?.length > 0" fxFlex="50" mat-raised-button
                color="warn"><span class="optionindicater">B</span> <span>{{currentQuizObj?.option_2}}</span></button>
        </div>
        <div fxLayout="row" style="height: 100px;" fxLayoutGap="10px">
            <button [disabled]="opt3Disabled" *ngIf="currentQuizObj?.option_3?.length > 0" fxFlex="50" mat-raised-button
                color="primary"><span class="optionindicater">C</span>
                <span>{{currentQuizObj?.option_3}}</span></button>
            <button [disabled]="opt4Disabled" *ngIf="currentQuizObj?.option_4?.length > 0" fxFlex="50"
                [ngStyle]="{'background-color': (opt4Disabled==false) ? 'darkcyan' : '', 'color': (opt4Disabled==false) ? 'white' : ''}"
                mat-raised-button><span class="optionindicater">D</span>
                <span>{{currentQuizObj?.option_4}}</span></button>
        </div>
    </div>
</div>