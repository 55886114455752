import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Track } from 'ngx-audio-player';
import { Subscription, timer } from 'rxjs';
import {
  AudioQues,
  quizData,
  QuizData,
  VideoQues,
} from '../interfaces/quizData';
import { PopupModalComponent } from '../popup-modal/popup-modal.component';
import { DataService } from '../services/data.service';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-quiz-screen',
  templateUrl: './quiz-screen.component.html',
  styleUrls: ['./quiz-screen.component.css'],
})
export class QuizScreenComponent implements OnInit, OnDestroy {
  quizData: QuizData;
  currentQuizObj: quizData;
  currentQuestion: number = 0;
  totalQuestions: number;
  countDown: Subscription;
  counter;
  tick = 1000;
  totalAnswers: number = 0;
  showChart: boolean = false;
  barChartLabels: any = [];
  barChartData: any = [
    {
      data: [],
      backgroundColor: [],
    },
  ];
  opt1Disabled: boolean = false;
  opt2Disabled: boolean = false;
  opt3Disabled: boolean = false;
  opt4Disabled: boolean = false;
  timerInterval: any;

  Audios_Twenty: string[] = [
    '../../assets/audios/001_20_second.mp3',
    '../../assets/audios/002_20_second.mp3',
    '../../assets/audios/003_20_second.mp3',
    '../../assets/audios/004_20_second.mp3',
    '../../assets/audios/005_20_second.mp3',
  ];

  Audios_Thirty: string[] = [
    '../../assets/audios/001_30_second.mp3',
    '../../assets/audios/002_30_second.mp3',
    '../../assets/audios/003_30_second.mp3',
    '../../assets/audios/004_30_second.mp3',
    '../../assets/audios/005_30_second.mp3',
  ];

  constructor(
    private dataService: DataService,
    private router: Router,
    private socketService: SocketIOService,
    public matDialog: MatDialog,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.quizData = this.dataService.quizData;
    if (this.dataService.currentQuesiton == undefined) {
      this.dataService.currentQuesiton = 0;
    }
    this.currentQuestion = this.dataService.currentQuesiton;
    this.totalQuestions = this.quizData?.quizData?.length;
    this.startQuiz();

    this.socketService.getNewAnswerByUser().subscribe((answersCount) => {
      this.animateCount(this.totalAnswers, answersCount, 450);
    });
  }

  startQuiz(): void {
    if (this.currentQuestion > this.totalQuestions - 1) {
      this.router.navigate(['/final-leaderboard']);
    }
    this.currentQuizObj = this.quizData?.quizData[this.currentQuestion];
    let counter = this.currentQuizObj?.time_in_sec;
    var that = this;
    if (counter == 20) {
      let currentAudio = this.Audios_Twenty[
        Math.floor(Math.random() * this.Audios_Twenty.length)
      ];
      this.playAudio(currentAudio);
    } else if (counter == 30) {
      let currentAudio = this.Audios_Thirty[
        Math.floor(Math.random() * this.Audios_Thirty.length)
      ];
      this.playAudio(currentAudio);
    }

    this.countDown = timer(0, this.tick).subscribe(() => {
      if (counter > 0) {
        --counter;
        that.counter = counter;
      } else {
        this.countDown.unsubscribe();
        this.showAnswerChart();
      }
    });
  }

  playAudio(path: string): void {
    let audio = new Audio();
    audio.src = path;
    audio.load();
    audio.play();
  }

  showAnswerChart(): void {
    this.openModal();
    setTimeout(() => {
      this.closeModal();
      this.showChartAfterModal();
    }, 3000);
  }

  showLeaderBoard(): void {
    this.socketService.showLeaderBoard();
    this.showChart = false;
    if (this.currentQuestion == this.totalQuestions - 1) {
      this.socketService.SendFinalLeaderBoard();
      this.router.navigate(['/final-leaderboard']);
    } else {
      this.router.navigate(['/leaderboard']);
    }
  }

  disableWrongOptions(): void {
    if (this.currentQuizObj?.question_type == 2) {
      this.disableMultiSelectOptions();
    } else {
      if (this.currentQuizObj?.option_1 != this.currentQuizObj?.correct_answer)
        this.opt1Disabled = true;
      if (
        this.currentQuizObj?.option_2 != this.currentQuizObj?.correct_answer
      ) {
        this.opt2Disabled = true;
      }
      if (
        this.currentQuizObj?.option_3 != this.currentQuizObj?.correct_answer
      ) {
        this.opt3Disabled = true;
      }
      if (
        this.currentQuizObj?.option_4 != this.currentQuizObj?.correct_answer
      ) {
        this.opt4Disabled = true;
      }
    }
  }

  disableMultiSelectOptions(): void {
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_1
      ) === -1
    ) {
      this.opt1Disabled = true;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_2
      ) === -1
    ) {
      this.opt2Disabled = true;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_3
      ) === -1
    ) {
      this.opt3Disabled = true;
    }
    if (
      this.currentQuizObj?.correct_answer.indexOf(
        this.currentQuizObj?.option_4
      ) === -1
    ) {
      this.opt4Disabled = true;
    }
  }

  prepareBarChartOptions(obj): void {
    if (this.currentQuizObj?.option_1?.length > 0) {
      this.barChartLabels.push('A');
      this.barChartData[0].data.push(obj.A);
      this.barChartData[0].backgroundColor.push('#ffd740');
    }
    if (this.currentQuizObj?.option_2?.length > 0) {
      this.barChartLabels.push('B');
      this.barChartData[0].data.push(obj.B);
      this.barChartData[0].backgroundColor.push('#f44336');
    }
    if (this.currentQuizObj?.option_3?.length > 0) {
      this.barChartLabels.push('C');
      this.barChartData[0].data.push(obj.C);
      this.barChartData[0].backgroundColor.push('#673ab7');
    }
    if (this.currentQuizObj?.option_4?.length > 0) {
      this.barChartLabels.push('D');
      this.barChartData[0].data.push(obj.D);
      this.barChartData[0].backgroundColor.push('darkcyan');
    }
    // this.barChartLabels.push('No Ans');
    // this.barChartData[0].data.push(obj.NoAnswer);
    // this.barChartData[0].backgroundColor.push('gray');
    this.showChart = true;
  }

  openModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '130px';
    dialogConfig.width = '300px';
    const modalDialog = this.matDialog.open(PopupModalComponent, dialogConfig);
  }

  closeModal(): void {
    this.matDialog.closeAll();
  }

  showChartAfterModal(): void {
    this.socketService.showResult();
    this.socketService.calculateUserRank();
    this.disableWrongOptions();
    this.dataService.currentQuesiton++;
    this.counter = 0;
    this.socketService.getAnswersGraph().subscribe((obj) => {
      this.prepareBarChartOptions(obj);
    });
  }

  animateCount(start, end, duration) {
    if (start == end) {
      //return;
    } else {
      var range = end - start;
      var current = start;
      var increment = 1;
      var stepTime = Math.abs(Math.floor(range / duration));
      var that = this;
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      this.timerInterval = setInterval(function () {
        current += increment;
        that.totalAnswers = current;
        if (current == end) {
          clearInterval(that.timerInterval);
        }
      }, stepTime);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
  }
}
