import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isValidCred: boolean = null;

  constructor(
    private formsBuilder: FormBuilder,
    private socketService: SocketIOService,
    private router: Router,
    private authenticateService: AuthenticateService
  ) {
    this.loginForm = this.formsBuilder.group({
      userName: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.socketService.getAdminStatus().subscribe((status) => {
      if (!status) {
        this.isValidCred = false;
      } else {
        localStorage.setItem('access_token', status);
        this.router.navigate(['/quiz-list']);
      }
    });
  }

  onSubmit(): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.socketService.authenticateAdmin(
      this.loginForm.value.userName,
      this.loginForm.value.password
    );
  }

  formClicked(): void {
    this.isValidCred = null;
  }
}
