import { Component, OnInit } from '@angular/core';
import { LeaderBoard } from '../interfaces/leaderBoard';
import { SocketIOService } from '../services/socketio.service';

export interface LeaderBoards {
  name: string;
  position: number;
  correctAns: number;
  score: number;
}

@Component({
  selector: 'app-final-leaderboard',
  templateUrl: './final-leaderboard.component.html',
  styleUrls: [
    './final-leaderboard.component.css',
    './leaderboardstyle.css',
    './podiumstyle.css',
  ],
})
export class FinalLeaderboardComponent implements OnInit {
  plyName: string;
  plyScore: number;

  newLeaderBoardList: LeaderBoard[] = [];

  showPos1: boolean = false;
  showPos2: boolean = false;
  showPos3: boolean = false;

  bodyStyle: boolean = false;
  legStyle: boolean = false;
  handStyle: boolean = false;

  showLeaderTable: boolean = false;
  podiumcntrStyle: boolean = false;
  podactive: boolean = false;

  constructor(private socketService: SocketIOService) {}

  ngOnInit(): void {
    this.socketService.showFinalScreen();

    this.socketService.GetFinalLeaderBoardAdmin().subscribe((leaderList) => {
      let rank = 1;
      for (let i = 0; i < leaderList.length; i++) {
        if (i % 2 == 0) {
          this.plyName = leaderList[i];
        } else {
          this.plyScore = leaderList[i];
          this.newLeaderBoardList.push({
            playerName: this.plyName,
            playerScore: this.plyScore,
            rank: rank,
          });
          this.plyName = '';
          this.plyScore = 0;
          rank++;
        }
      }

      setTimeout(() => {
        this.showPos1 = true;
      }, 3000);

      setTimeout(() => {
        this.showPos3 = true;
      }, 6000);

      setTimeout(() => {
        this.showPos2 = true;
      }, 9000);

      setTimeout(() => {
        this.showLeaderTable = true;
        this.podiumcntrStyle = true;
        this.podactive = true;
      }, 12000);

      setInterval(() => {
        this.legStyle = true;
        this.handStyle = true;
        this.bodyStyle = true;
      }, 1000);

      setInterval(() => {
        this.legStyle = false;
        this.handStyle = false;
        this.bodyStyle = false;
      }, 3000);
    });
  }
}
