<!-- <div fxLayout="column" fxLayoutAlign="center center" class="mb-16">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Login Page</mat-card-title>
        </mat-card-header>
        <form [formGroup]="loginForm" (submit)="onSubmit()" (click)="formClicked()">
            <mat-card-content fxLayout="column">
                <mat-form-field>
                    <input matInput formControlName="userName" placeholder="Email">
                    <mat-error *ngIf="loginForm.controls.userName.touched && loginForm.controls.userName.invalid">
                        <span *ngIf="loginForm.controls.userName.errors.required">This field is mandatory.</span>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input type="password" matInput formControlName="password" placeholder="password">
                    <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
                        <span *ngIf="loginForm.controls.password.errors.required">This field is mandatory.</span>
                    </mat-error>
                </mat-form-field>
                <mat-error>
                    <span *ngIf="isValidCred !== null && !isValidCred">Invalid Username or password. Please try
                        again</span>
                </mat-error>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary">Login</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div> -->
<form class="mainDiv" fxLayoutAlign="center center" name="form" [formGroup]="loginForm" (submit)="onSubmit()"
    (click)="formClicked()">
    <mat-card fxLayout="column">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="55">
                <mat-label>Name</mat-label>
                <input matInput formControlName="userName" placeholder="Name" required>
                <mat-error>Name is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="55">
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" placeholder="Password" required>
                <mat-error>Password is required!</mat-error>
            </mat-form-field>
            <mat-error>
                <span *ngIf="isValidCred !== null && !isValidCred">Invalid Username or password. Please try
                    again</span>
            </mat-error>
            <mat-card-actions fxFlex="30">
                <button mat-raised-button color="primary">Login</button>
            </mat-card-actions>
        </div>
    </mat-card>
</form>