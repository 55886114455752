import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { LeaderBoard } from '../interfaces/leaderBoard';
import { QuizData } from '../interfaces/quizData';

@Injectable({
  providedIn: 'root',
})
export class SocketIOService {
  socket: any;

  constructor(private router: Router) {}

  setUpSocketConnection(): void {
    this.socket = io(environment.SOCKET_IO_ENDPOINT);
  }

  authenticateAdmin(userName: string, password: string): void {
    this.socket.emit('authenticateAdmin', { user: userName, pass: password });
  }

  getAdminStatus(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('getAdminStatus', (status) => {
        observer.next(status);
      });
    });
  }

  startSelectedQuiz(quizName): void {
    this.socket.emit('startSelectedQuiz', this.getAccessToken());
  }

  startDemoQuiz(quizName): void {
    this.socket.emit('startDemoQuiz', this.getAccessToken());
  }

  demoQuizStatus(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('DemoQuizStatusForAdmin', (msg) => {
        observer.next(msg);
      });
    });
  }

  quizStartedStatus(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('QuizStatus', (msg: QuizData) => {
        observer.next(msg);
      });
    });
  }

  getNewUser(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('NewUserAdded', (obj) => {
        observer.next(obj);
      });
    });
  }

  startNewQuiz(): void {
    this.socket.emit('startNewQuiz', this.getAccessToken());
  }

  getQuizStartedByAdmin(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('QuizStartedByAdmin', () => {
        observer.next();
      });
    });
  }

  setQuizStartedFromQuestionScreen(currentQuestion: number): void {
    this.socket.emit('QuizStartedFromQuestionScreen', this.getAccessToken());
  }

  getNewAnswerByUser(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('NewAnswerByUser', (answersCount) =>
        observer.next(answersCount)
      );
    });
  }

  showNextQuestion(): void {
    this.socket.emit('ShowNextQuestion', this.getAccessToken());
  }

  showResult(): void {
    this.socket.emit('ShowResult', this.getAccessToken());
  }

  showFinalScreen(): void {
    this.socket.emit('ShowFinalScreen', this.getAccessToken());
  }

  showLeaderBoard(): void {
    this.socket.emit('ShowLeaderBoard', this.getAccessToken());
  }

  getLeaderBoard(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('ShowLeaderBoardAdmin', (leaderList: LeaderBoard[]) => {
        observer.next(leaderList);
      });
    });
  }

  getAnswersGraph(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('ShowAnswersGraph', (obj) => {
        observer.next(obj);
      });
    });
  }

  calculateUserRank(): void {
    this.socket.emit('CalculateRank', this.getAccessToken());
  }

  handleAdminRefresh(): void {
    this.socket.emit('HandleAdminRefresh', this.getAccessToken());
  }

  RefreshedDataFromServer(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('DataForAdminRefresh', (resp) => {
        observer.next(resp);
      });
    });
  }

  QuizStatusForAdmin(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('QuizStatusForAdmin', (resp) => {
        observer.next(resp);
      });
    });
  }

  SendFinalLeaderBoard(): void {
    this.socket.emit('SendFinalLeaderBoard', this.getAccessToken());
  }

  GetFinalLeaderBoardAdmin(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('ShowFinalLeaderBoardAdmin', (resp) => {
        observer.next(resp);
      });
    });
  }

  GetAuthenticationError() {
    return new Observable((observer) => {
      this.socket.on('AuthenticationErrorEvent', (resp) => {
        observer.next(resp);
      });
    });
  }

  getAccessToken(): string {
    return localStorage.getItem('access_token');
  }
}
