<div fxLayout="column" class="mainDiv">
    <div fxLayout="row" class="scoreboardSpan" fxLayoutAlign="center center">
        <span>SCOREBOARD</span>
    </div>
    <div class="leaderBoardDiv">
        <div class="nextQuestionDiv" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="16px">
            <button class="nextButton" mat-raised-button color="primary" (click)="showNextQuestion()">Next</button>
        </div>
        <div class="leaderPlayersDiv" fxLayout="row" fxLayoutAlign="center start">
            <!-- <mat-list style="width: 50%;">
                <mat-list-item class="matListItem" *ngFor="let player of leaderBoardList" [@anim]="player">
                    <span style="width: 60%;">{{player.playerName}}</span>
                    <span style="width: 40%;text-align: right;">{{player.playerScore}}</span>
                </mat-list-item>
            </mat-list> -->

            <ul style="width: 50%;list-style: none;padding: 0;" [@.disabled]="!applyAnimation">
                <li class="matListItem scoreboardlist item" *ngFor="let item of leaderBoardList" [@anim]="item">
                    <span style="width: 15%;float: left;text-align: left;">{{item.rank}}</span>
                    <span style="width: 50%;float: left;">{{item.playerName}}</span>
                    <span style="width: 30%;float: right;text-align: right;">{{item.playerScore}}</span>
                    <span class="success-failure-cntr">
                        <i *ngIf="item.isPositionChanged == 'Up'" class="success">↑</i>
                        <!-- <i *ngIf="item.isPositionChanged == 'Down'" class="failure">↓</i> -->
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>