import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocketIOService } from './services/socketio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'socketio-Admin';

  constructor(private socketIOService: SocketIOService, private router: Router){
    
  }

  ngOnInit(): void{
    this.socketIOService.setUpSocketConnection();

    // If not authenticated, Navigate to Login Page
    this.socketIOService.GetAuthenticationError().subscribe((resp) => {
      localStorage.clear();
      this.router.navigate(['/login']);
    })
  }
}
