import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SocketIOService } from '../services/socketio.service';

@Component({
  selector: 'app-wait-screen',
  templateUrl: './wait-screen.component.html',
  styleUrls: ['./wait-screen.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitScreenComponent
  implements OnInit, AfterContentChecked, OnDestroy {
  userList = [];
  totalPlayers: number = this.userList?.length;
  newUserSub: Subscription;
  constructor(
    private socketService: SocketIOService,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.newUserSub = this.socketService.getNewUser().subscribe((obj: any) => {
      this.updateUserList(obj);
    });

    this.socketService.getQuizStartedByAdmin().subscribe(() => {
      this.socketService.showNextQuestion();
      this.router.navigate(['/question-only']);
    });
  }

  updateUserList(obj: any) {
    let userName = obj.name;
    let count = obj.count;
    this.userList.unshift(userName);
    this.totalPlayers = count - 1;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  onStartClicked(): void {
    this.socketService.startNewQuiz();
  }

  ngOnDestroy(): void {
    this.newUserSub.unsubscribe();
  }
}
