import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthenticateService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (localStorage.getItem('access_token')) {
      return true;
    }
    else{
      this.router.navigate(['/login']);
      return false;
    }
  }
}
